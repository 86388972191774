import { dayFromAny, last, toCapitalizeWords } from 'zeed'
import type { PostMeta } from '../_types'

export function extractDate(s: string) {
  const r = /^(\d\d\d\d)-(\d\d)-(\d\d)[\s\-_]/m.exec(s)
  if (r) {
    const [result, yyyy, mm, dd] = r
    if (result) {
      return `${yyyy}${dd}${mm}`
    }
  }
}

/** Normalize frontmatter info */
export function parseFrontmatter(data: Record<string, any>, path: string): PostMeta {
  const src = last(path.split('/')) ?? ''
  const slug = data.slug || src.replace(/-(en|de)$/gim, '')
  const lang = data.lang
  const draft = data.draft
  const tags = data?.tags ?? data?.taglist
  const desc = data.description ?? data.desc
  const day = dayFromAny(data.date as string ?? extractDate(src))
  const heading = data.heading ?? data.head ?? data.headline
  const outline = data.outline
  const priority = data.priority ?? 1

  // let title = data.title
  // if (content.trim().startsWith('# ')) {
  //   const lines = content.trim().substring(2).split('\n')
  //   title = lines.shift()
  //   content = lines.join('\n').trimStart()
  // }

  // const [excerpt, ..._rest] = content.split(/\n---+\s*\n/gim)

  return {
    title: data.title || toCapitalizeWords(src) || 'untitled',
    slug,
    src,
    lang,
    day,
    tags,
    draft,
    desc,
    heading,
    outline,
    priority: priority >= 1 ? undefined : priority,
    // data,
    // excerpt: excerpt.trim() ?? '',
    // content,
  }
}
